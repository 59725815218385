import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import Terms from './Terms.js';
import Releases from './Releases.js';
import Cookies from './Cookies.js';
import Privacy from './Privacy.js';
import './fonts/Graphik-Regular-Web.woff2';
import './fonts/Graphik-Medium-Web.woff2';
import './fonts/Graphik-Bold-Web.woff2';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const Webpages = () => {
  return(
      <Router>
          <Route exact path="/" component= {App} />
          <Route path = "https://jetique.de/terms" component = {Terms} />
          <Route path = "/cookies" component = {Cookies} />
          <Route path = "/privacy" component = {Privacy} />
      </Router>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





