import React from 'react';
import thumbnail from '../picture.jpg';
import '../App.css';
import Releases from "../Releases.js";
import Title from "../headertitle.js";
import Mail from '../Mailform.js';
import Footer from '../Footer';

const Home = () => {
    return (
        <div className="App">
      <header className="App-header">
        <div className="App-image-wrapper">
        <img src={thumbnail} className="App-image" alt="logo" />
        </div>
        <h1>
          Jetique
        </h1>
        <Title />
        
      </header>
      <section className="sec-releases">
      <div className="row row1">
          <div className="col col1">
            <h2>Music</h2>
              <Releases />
          </div>
        </div>
      </section>
      <section className="sec-video">
      <div className="row row2">
          <div className="col col2">
            <h2>Videos</h2>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/DIWEzTwBv1g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
      </section>
      <section className="Contact">
      <div className="row row3">
        <div className="col col3">
          <h2>Contact</h2>
          <p>Send any questions or inquiries below</p>
          <Mail />
        </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
    );
};


export default Home;