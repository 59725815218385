import './App.css';
import Webpages from './webpages';



function App() {
  return (
    <Webpages />
  );
}

export default App;
