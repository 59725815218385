import React from 'react'
class Title extends React.Component {
  render(){
    return <div className="title-flex-box">
        <a className="btn btn-title btn-spotify" href="https://open.spotify.com/artist/3GYZ53gXgogSmunnJ9Xv1I?si=WuQrceBvSGS2ljBMjMfjvg2&nd=1" target="_blank" rel="noreferrer">Spotify</a>
        <a className="btn btn-title btn-sc" href="https://soundcloud.com/jetiquemusic" target="_blank" rel="noreferrer">SoundCloud</a>
        <a className="btn btn-title btn-yt" href="https://www.youtube.com/channel/UC_zHQeMaxpjFJXuUdcgq9tA" target="_blank" rel="noreferrer">YouTube</a>
        <a className="btn btn-title btn-ig" href="https://www.instagram.com/jetique" target="_blank" rel="noreferrer">Instagram</a>
        </div>

  }
}

export default Title