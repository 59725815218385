import React from 'react';
import axios from 'axios';

class Mail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    };
  }

  onNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  onMsgChange = (event) => {
    this.setState({ message: event.target.value });
  }

  submitEmail = (e) => {
    e.preventDefault();
    console.log("Form data:", this.state);
    axios.post('https://jetique.de/send', this.state)
      .then((response) => {
        if (response.data.status === 'success') {
          alert('Message Sent.');
          this.resetForm();
        } else if (response.data.status === 'fail') {
          alert('Message failed to send.');
        }
      })
      .catch((error) => {
        console.error('There was an error sending the message!', error);
      });
  }
  
  resetForm = () => {
    this.setState({ name: '', email: '', message: '' });
  }

  render() {
    return (
      <div className="Message">
        <form id="contact-form" onSubmit={this.submitEmail} method="POST">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={this.state.name}
              onChange={this.onNameChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              aria-describedby="emailHelp"
              value={this.state.email}
              onChange={this.onEmailChange}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Message"
              rows="5"
              value={this.state.message}
              onChange={this.onMsgChange}
              required
            />
          </div>
          <input
            type="submit"
            className="btn btn-primary btn-submit"
            value="Submit"
          />
        </form>
      </div>
    );
  }
}

export default Mail;