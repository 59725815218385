import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './home';
import TTerms from './terms.js';
import Cookies from './cookies.js';
import Privacy from './privacy.js';
const Webpages = () => {
    return(
        <Router>
            <Route exact path="/" component= {Home} />
            <Route path = "/terms" component = {TTerms} />
            <Route path = "/cookies" component = {Cookies} />
            <Route path = "/privacy" component = {Privacy} />
        </Router>
    );
};
export default Webpages;