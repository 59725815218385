import React from 'react'
class Footer extends React.Component {
  render(){
      
    return <footer class="footer">
    <ul class="footer-links">
        <li>
            <a class="privacy-policy-link" href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </li>
        <li>
            <a class="tou-link" href="/terms" target="_blank" rel="noreferrer">Terms Of Service</a>
        </li>
        
        <li>
            <a class="cookie-policy-link" href="/cookies" target="_blank" rel="noreferrer">Cookie Policy</a>
        </li>
    </ul>
</footer>

    }
}

export default Footer