import React, { useEffect, useState } from "react";
import {isMobile} from 'react-device-detect';

/*
const Releases = () => {

const [releases, setReleases] = useState({});


useEffect(() => {
    fetch("https://api.airtable.com/v0/appQQDRRzN9gxabAx/Table%201?api_key=key2N0fOW3PRXlija&sort%5B0%5D%5Bfield%5D=releasedate&sort%5B0%5D%5Bdirection%5D=desc")
      .then((res) => res.json())
      .then((data) => {
        setReleases(data.records);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
      
  }, []);  */


  const Releases = () => {
    const [releases, setReleases] = useState([]);
    const AIRTABLE_API_URL = 'https://api.airtable.com/v0/appQQDRRzN9gxabAx/record?sort%5B0%5D%5Bfield%5D=releasedate&sort%5B0%5D%5Bdirection%5D=desc';
    const AIRTABLE_API_KEY = 'patpRWYS8sVROGPVX.6ca9b869efa32e22a5a7ce1ad5105c336f0e62e1dcaba54c1351620bd25dcd46';
  
    useEffect(() => {
      fetch(AIRTABLE_API_URL, {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setReleases(data.records);
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

  const [readMore,setReadMore]=useState(false);


  const extraContent = 
        
    isMobile ? releases.length > 0 ? ( 
      releases.slice(3, 9).map((record)=> (
        <li href={record.fields.url} key={record.id}>
          <img src={record.fields.Image[0].url} alt={record.fields.releases} />
          <p>{record.fields.releases}</p>
          <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
        </li>
      ))
    ): ([]) :
    
    releases.length > 0 ? ( 
      releases.slice(6, 9).map((record)=> (
        <li href={record.fields.url} key={record.id}>
          <img src={record.fields.Image[0].url} alt={record.fields.releases} />
          <p>{record.fields.releases}</p>
          <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
        </li>
      ))
    ): ([])
  
  const linkName=readMore?'See Less':'See More'
      
  if(isMobile && releases.length < 4) {
    return (
      <ul>
       
      {releases.length > 0 ? (
        releases.slice(0, 3).map((record) => (
          <li href={record.fields.url} key={record.id}>
            <img src={record.fields.Image[0].url} alt={record.fields.releases} />
            <p>{record.fields.releases}</p>
            <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
          </li>
         
        )) 
      ) : (
        <div className="placeholder-ul">
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          
        </div>
      )} 
      
     
    
      {readMore && extraContent}<div className="btn-seemore-wrapper btn-hidden"><a className="btn btn-releases btn-seemore btn-hidden" onClick={() => { setReadMore(!readMore); } }>{linkName}</a>
         </div>
    
      </ul>
    );
  };

  if(isMobile) {
    return (
      <ul>
      {releases.length > 0 ? (
        releases.slice(0, 3).map((record) => (
          <li href={record.fields.url} key={record.id}>
            <img src={record.fields.Image[0].url} alt={record.fields.releases} />
            <p>{record.fields.releases}</p>
            <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
          </li>
        
        )) 
        
      )  : (
        <div className="placeholder-ul">
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
          <li className="placeholder-li">
            <div className="placeholder-img"></div>
              <p className="placeholder-p"></p>
              <a className="btn btn-releases btn-placeholder">Stream</a>
          </li>
        
        </div>
      )}  
        {readMore && extraContent}<div className="btn-seemore-wrapper"><a className="btn btn-releases btn-seemore" onClick={() => { setReadMore(!readMore); } }>{linkName}</a>
         </div>
    
      </ul>
    )
};

if(releases.length < 7) {
  return (
    <ul>
    
    {releases.length > 0 ? (
      releases.slice(0, 6).map((record) => (
        <li href={record.fields.url} key={record.id}>
          <img src={record.fields.Image[0].url} alt={record.fields.releases} />
          <p>{record.fields.releases}</p>
          <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
        </li>
      ))
    ) : (
      <div className="placeholder-ul">
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        
      </div>
    )} 
    
   
  
    {readMore && extraContent}<div className="btn-seemore-wrapper btn-hidden"><a className="btn btn-releases btn-seemore btn-hidden" onClick={() => { setReadMore(!readMore); } }>{linkName}</a>
       </div>
  
    </ul>
  );
};

  return (
  <ul>
    
    {releases.length > 0 ? (
      releases.slice(0, 6).map((record) => (
        <li href={record.fields.url} key={record.id}>
          <img src={record.fields.Image[0].url} alt={record.fields.releases} />
          <p>{record.fields.releases}</p>
          <a className="btn btn-releases" href={record.fields.SmartLinks} target="_blank" rel="noreferrer">Stream</a>
        </li>
      ))
    ) : (
      <div className="placeholder-ul">
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        <li className="placeholder-li">
          <div className="placeholder-img"></div>
            <p className="placeholder-p"></p>
            <a className="btn btn-releases btn-placeholder">Stream</a>
        </li>
        
      </div>
    )} 
    
   
  
    {readMore && extraContent}<div className="btn-seemore-wrapper"><a className="btn btn-releases btn-seemore" onClick={() => { setReadMore(!readMore); } }>{linkName}</a>
       </div>
  
    </ul>
    
    );
    };

export default Releases;


