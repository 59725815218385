//file: src/webpages/Terms.js
import React from 'react';
import Terms from '../Terms.js';
import '../Policies.css';
import Footer from '../Footer';
const terms = () => {
    return (
         <div className ="wrapper">
            <Terms />
            <Footer />
        </div>
    );
};
export default terms;