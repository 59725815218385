import React from 'react';
import Cookies from '../Cookies.js';
import '../Policies.css';
import Footer from '../Footer';
const Cookies2 = () => {
    return (
        <div className ="wrapper">
        <Cookies />
        <Footer />
        </div>
    );
};
export default Cookies2;