//file: src/webpages/Terms.js
import React from 'react';
import Privacy from '../Privacy.js';
import '../Policies.css';
import Footer from '../Footer';
const privacy = () => {
    return (
        <div className ="wrapper">
        <Privacy />
        <Footer />
        </div>

        );
};
export default privacy;